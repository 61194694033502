import {useTranslation} from 'hooks/translations/useCustomTranslation';
import useCustomController from 'hooks/useCustomController';
import {ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';

import {StandardTextFieldProps} from '@mui/material';

import TextField from '../TextField';
import styles from './FormInputNativeDate.module.scss';

interface FormInputNativeDateProps extends Omit<StandardTextFieldProps, 'variant'> {
  name: string;
  label?: ReactNode;
  variant?: 'standard' | 'filled' | 'outlined';
  subText?: string;
  sx?: StandardTextFieldProps['sx'];
  validateOnBlur?: boolean;
}
export const FormInputNativeDate = ({
  name,
  label,
  variant,
  subText,
  sx,
  validateOnBlur,

  ...rest
}: FormInputNativeDateProps) => {
  const {t} = useTranslation('forms');

  const {control, trigger} = useFormContext();

  const {
    fieldState: {error},
    field: {value, onChange, onBlur},
  } = useCustomController({
    control,
    name,
    defaultValue: '',
  });

  const handleInput = event => {
    const target = event.nativeEvent.target;
    function iosClearDefault() {
      target.defaultValue = '';
    }
    window.setTimeout(iosClearDefault, 0);
  };

  return (
    <TextField
      type="date"
      variant={variant}
      error={Boolean(error?.message)}
      helperText={
        subText ? (
          <>
            {error?.message}
            {error?.message ? <br></br> : null}
            {subText}
          </>
        ) : (
          error?.message
        )
      }
      label={label}
      {...rest}
      value={value}
      onChange={onChange}
      onBlur={event => {
        if (validateOnBlur) {
          trigger(name);
        }
        (onBlur as any)?.(event);
      }}
      onInput={handleInput}
      name={name}
      className={styles.dateField}
      inputProps={{
        max: '9999-12-31',
        min: '1900-01-01',
        placeholder: t('dateInputPlaceholder'),
        'data-empty': value === '' ? 'noValue' : 'hasValue',
      }}
      sx={{
        '& input[data-empty="noValue"]:before': {
          content: `'${t('dateInputPlaceholder')}'`,
          width: '8000%',
          fontWeight: '400',
        },
        ...sx,
      }}
    />
  );
};
