import {BackgroundStyleContext} from 'context/BackgroundStyleContext';
import {useContext} from 'react';

import {SubcomponentsContentWrapperComponentBackgroundColor} from 'lib/api/strapi';

import {palette} from 'styles/mui/scss';

import {ExtendedButtonProps} from 'components/basic-components/Button/Button';

const themeConfig: {
  [key in SubcomponentsContentWrapperComponentBackgroundColor]: {
    buttonStyle: ExtendedButtonProps;
    uploadButtonStyle: ExtendedButtonProps;
    theme: SubcomponentsContentWrapperComponentBackgroundColor;
    textColor: string;
    backgroundColor: string;
    accordion: {
      backgroundColor: string;
      textColor: string;
    };
    ctaColor: string;
    iconColor: string;

    seperatorColor: string;
    imageFrame: string;
    seeThroughBackgroundColor: string;
    resultCardMobileBackgroundColor: string;
    bannerColor: string;
    groupInfoSection: {
      buttonStyle: ExtendedButtonProps;
    };
    businessCardsSection: {
      ctaColor: string;
    };
    colGrid: {
      textColor: string;
      ctaColor: string;
    }
    cardGrid: {
      headingTopColor: string;
      descriptionTopColor: string;
      boxColor: string;
      headingColor: string;
      descriptionColor: string;
      ctaColor: string;
    };
    map: {
      hover: {
        selected: string;
        default: string;
      };
      fill: {
        selected: string;
        default: string;
      };
      stroke: {
        selected: string;
        default: string;
      };
    };
  };
} = {
  white: {
    theme: 'white',
    buttonStyle: {variant: 'inverted'},
    uploadButtonStyle: {variant: 'contained'},
    backgroundColor: palette.colorWhite,
    textColor: palette.colorBlack,
    ctaColor: palette.colorPrimary,
    iconColor: palette.colorBlack,

    seperatorColor: palette.colorGrey10,
    seeThroughBackgroundColor: palette.colorGrey03,
    resultCardMobileBackgroundColor: palette.colorLightGrey,
    bannerColor: palette.colorPrimary,
    accordion: {
      backgroundColor: palette.colorGrey03,
      textColor: palette.colorBlack,
    },
    imageFrame: palette.colorBlack,
    groupInfoSection: {
      buttonStyle: {variant: 'contained'},
    },
    businessCardsSection: {
      ctaColor: palette.colorBlack,
    },
    colGrid: {
      textColor: palette.colorBlack,
      ctaColor: palette.colorBlack,
    },
        cardGrid: {
      headingTopColor: palette.colorBlack,
      descriptionTopColor: palette.colorBlack,
      boxColor: palette.colorGrey90,
      headingColor: palette.colorWhite,
      descriptionColor: palette.colorWhite,
      ctaColor: palette.colorPrimary,
    },
    
    map: {
      hover: {
        selected: palette.colorPrimary,
        default: '',
      },
      fill: {
        selected: palette.colorPrimary,
        default: palette.colorGrey,
      },
      stroke: {
        selected: palette.colorGrey,
        default: palette.colorPrimary,
      },
    },
  },
  black: {
    theme: 'black',
    buttonStyle: {variant: 'contained'},
    uploadButtonStyle: {variant: 'contained'},
    backgroundColor: palette.colorDarkGrey,
    textColor: palette.colorWhite,
    ctaColor: palette.colorPrimary,
    iconColor: palette.colorPrimary,

    seperatorColor: palette.colorWhite10,
    seeThroughBackgroundColor: palette.colorGrey,
    resultCardMobileBackgroundColor: palette.colorLightGrey,
    bannerColor: palette.colorPrimary,
    accordion: {
      backgroundColor: palette.colorWhite10,
      textColor: palette.colorWhite,
    },
    imageFrame: palette.colorWhite,
    groupInfoSection: {
      buttonStyle: {variant: 'contained'},
    },
    businessCardsSection: {
      ctaColor: palette.colorPrimary,
    },
    colGrid: {
      textColor: palette.colorWhite,
      ctaColor: palette.colorPrimary,
    },
        cardGrid: {
      headingTopColor: palette.colorWhite,
      descriptionTopColor: palette.colorWhite,
      boxColor: palette.colorPrimary,
      headingColor: palette.colorBlack,
      descriptionColor: palette.colorBlack,
      ctaColor: palette.colorBlack,
    },
    map: {
      hover: {
        selected: palette.colorPrimary,
        default: '',
      },
      fill: {
        selected: palette.colorPrimary,
        default: palette.colorGrey,
      },
      stroke: {
        selected: palette.colorGrey,
        default: palette.colorPrimary,
      },
    },
  },
  yellow: {
    theme: 'yellow',
    buttonStyle: {variant: 'inverted'},
    uploadButtonStyle: {variant: 'inverted'},
    backgroundColor: palette.colorPrimary,
    textColor: palette.colorBlack,
    ctaColor: palette.colorBlack,
    iconColor: palette.colorBlack,

    seperatorColor: palette.colorBlack,
    seeThroughBackgroundColor: palette.colorGrey03,
    resultCardMobileBackgroundColor: palette.colorLightGrey,
    bannerColor: palette.colorDarkGrey,
    accordion: {
      backgroundColor: palette.colorGrey03,
      textColor: palette.colorBlack,
    },
    imageFrame: palette.colorWhite,
    groupInfoSection: {
      buttonStyle: {variant: 'inverted'},
    },
    businessCardsSection: {
      ctaColor: palette.colorBlack,
    },
    colGrid: {
      textColor: palette.colorBlack,
      ctaColor: palette.colorBlack,
    },
    cardGrid: {
      headingTopColor: palette.colorBlack,
      descriptionTopColor: palette.colorBlack,
      boxColor: palette.colorGrey90,
      headingColor: palette.colorWhite,
      descriptionColor: palette.colorWhite,
      ctaColor: palette.colorPrimary,
    },
    map: {
      hover: {
        selected: palette.colorPrimary,
        default: '',
      },
      fill: {
        selected: palette.colorPrimary,
        default: palette.colorGrey,
      },
      stroke: {
        selected: palette.colorGrey,
        default: palette.colorPrimary,
      },
    },
  },
  grey: {
    theme: 'grey',
    buttonStyle: {variant: 'inverted'},
    uploadButtonStyle: {variant: 'contained'},
    backgroundColor: palette.colorLightGrey,
    textColor: palette.colorBlack,
    ctaColor: palette.colorPrimary,
    iconColor: palette.colorBlack,

    seperatorColor: palette.colorGrey10,
    seeThroughBackgroundColor: palette.colorGrey03,
    resultCardMobileBackgroundColor: palette.colorWhite,
    bannerColor: palette.colorPrimary,
    accordion: {
      backgroundColor: palette.colorGrey03,
      textColor: palette.colorBlack,
    },
    imageFrame: palette.colorBlack,
    groupInfoSection: {
      buttonStyle: {variant: 'contained'},
    },
    businessCardsSection: {
      ctaColor: palette.colorBlack,
    },
    colGrid: {
      textColor: palette.colorBlack,
      ctaColor: palette.colorPrimary,
    },
        cardGrid: {
      headingTopColor: palette.colorBlack,
      descriptionTopColor: palette.colorBlack,
      boxColor: palette.colorGrey90,
      headingColor: palette.colorWhite,
      descriptionColor: palette.colorWhite,
      ctaColor: palette.colorPrimary,
    },
    map: {
      hover: {
        selected: palette.colorPrimary,
        default: '',
      },
      fill: {
        selected: palette.colorPrimary,
        default: palette.colorGrey,
      },
      stroke: {
        selected: palette.colorGrey,
        default: palette.colorPrimary,
      },
    },
  },
};

export const useStaticBackgroundStyleConfigs = (
  themeProp: SubcomponentsContentWrapperComponentBackgroundColor = 'white',
) => {
  return themeConfig[themeProp] || themeConfig['white'];
};
export const useBackgroundStyleConfigs = (
  themeProp: SubcomponentsContentWrapperComponentBackgroundColor = 'white',
) => {
  const {theme} = useContext(BackgroundStyleContext);
  if (!theme) {
    return themeConfig[themeProp];
  }
  return themeConfig[theme] || themeConfig[themeProp];
};
