import useCustomController from 'hooks/useCustomController';
import {useRouter} from 'next/router';
import {ReactNode, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import {StandardTextFieldProps} from '@mui/material';

import formatNumber from 'utils/formateHelpers/formatNumber';

import TextField from '../TextField';

interface FormInputNumberProps extends StandardTextFieldProps {
  name: string;
  label: ReactNode;
  placeholder?: string;
  maxLength?: number;
  regex?: RegExp;
  subText?: string;
  formatValue?: boolean;
  validateOnBlur?: boolean;
}
export const FormInputNumber = ({
  name,
  label,
  placeholder,
  maxLength,
  regex,
  subText,
  formatValue = false,
  validateOnBlur,

  ...rest
}: FormInputNumberProps) => {
  const {
    formState: {isSubmitted},
    control,
    trigger,
    clearErrors
  } = useFormContext();
  const {
    fieldState: {error},
    field: {value, onChange, onBlur},
  } = useCustomController({control, name, defaultValue: ''});

  const {locale} = useRouter();
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (value && formatValue) {
      const formattedDistance = formatNumber(value, locale);
      setDisplayValue(formattedDistance);
    } else {
      setDisplayValue(value);
    }
  }, [value, locale]);

  const handleChange = e => {
    const regexp = regex || /^[0-9\b]+$/;
    let value = e.target.value;
    if (
      (value === '' || regexp.test(value.replace(/[.,]/g, ''))) &&
      (!maxLength || value?.length <= maxLength)
    ) {
      const numericValue = value.replace(/\D/g, '');

      if (numericValue && formatValue) {
        const formattedValue = formatNumber(numericValue, locale);
        setDisplayValue(formattedValue);
      } else {
        setDisplayValue(value);
      }

      onChange(numericValue);
    }
  };

  return (
    <TextField
      error={Boolean(error?.message)}
      helperText={
        subText ? (
          <>
            {error?.message}
            {error?.message ? <br></br> : null}
            {subText}
          </>
        ) : (
          error?.message
        )
      }
      label={label}
      placeholder={placeholder}
      {...rest}
      value={displayValue}
      onChange={handleChange}
      onBlur={event => {
        if (validateOnBlur && !!value) {
          trigger(name);
        }
        if (!value && !isSubmitted) {
          clearErrors(name);
        }
        (onBlur as any)?.(event);
      }}
      name={name}
    />
  );
};
